<template>
	<div v-html="cookiesText" :key="key" class="cookies"></div>
</template>

<script>
import { getPolicyLang } from '@/api/policy.js';
export default {
	name: 'CookiesComponent',
	data() {
		return {
			cookiesText: '',
			key: 0
		};
	},

	async created() {
		await this.getCookiesText();
	},

	methods: {
		async getCookiesText() {
			const lang = window.localStorage.getItem('pui9_lang');
			const data = await getPolicyLang(lang, 'cookies');
			this.cookiesText = data;
			this.key++;
		}
	},
	watch: {
		'$store.state.activeLanguage'(newVal) {
			if (newVal) {
				this.getCookiesText();
			}
		}
	}
};
</script>

<style scoped>
.cookies {
	margin-top: 2%;
}
</style>
